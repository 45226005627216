import React from "react";
import {Props} from "./props";
import "./styles.scss";
import {Link} from "react-router-dom";

export const CreateSteps: React.FC<Props> = ({ title, steps }) => {
  return (
    <div className="create-steps-container">
      <h2>{title}</h2>
      <div className="steps">
        {steps.map((step, i) => (
          <>
            <div className="step">
              {i !== 0 && (
                i % 2 !== 0 ? (
                  <img className="arrow-left" src="/images/arrow-left.svg" alt="arrow" />
                ) : (
                  <img className="arrow-right" src="/images/arrow-right.svg" alt="arrow" />
                )
              )}
              <img className="step-image" src={step.imageSrc} alt=""/>
              <h4>{i + 1}. {step.label}</h4>
              <p>{step.description}</p>
              {step.externalLink && (
                <Link className="external-link" to="/use-cases/ecommerce-and-marketplace">{step.externalLink} <img src="/images/external-link-primary.svg" alt="" /></Link>
              )}
            </div>
            {step.breakAfter && (
              <div className="break" />
            )}
          </>
        ))}
      </div>
    </div>
  );
};
