import React, {useCallback, useState} from "react";
import {Button} from "../Button/component";
import {useDispatch} from "react-redux";
import {setEmailForModal, setModalOpen} from "../../store/store";
import "./styles.scss";

export const Assay: React.FC = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const handleChangeEmail = useCallback((val: string) => {
    setEmail(val);
  }, []);

  const handleOpenModalWithEmail = useCallback(() => {
    dispatch(setEmailForModal(email));
    dispatch(setModalOpen(true));
  }, [email]);

  return (
    <div className="assay">
      <div className="assay-form">
        <h3>Get started for free or reach out to our sales team</h3>
        <p className="form-subtitle">Get notified about company updates, news and blog posts. No spam, we promise!</p>
        <div className="d-flex flex-md-row flex-column mb-2">
          <input onChange={(e) => handleChangeEmail(e.target.value)} placeholder="Type your e-mail" type="email" />
          <Button onClick={handleOpenModalWithEmail} contained color="dark">Get Started</Button>
        </div>
        <p className="support-text text-start">By clicking you accept the processing of personal data</p>
      </div>
    </div>
  )
};
