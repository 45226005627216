import React from "react";
import {Props} from "./props";
import "./styles.scss";

export const UseCaseItem: React.FC<Props> = ({ title, subtitle, imageSrc }) => {
  return (
    <div className="use-case-head-block">
      <div className="info">
        <h5>Use cases</h5>
        <h1>{title}</h1>
        <p className="paragraph">{subtitle}</p>
      </div>
      <img src={imageSrc} alt="content hub" />
    </div>
  )
};
