import React, {useCallback, useState} from "react";
import './styles.scss';
import {Button} from "../Button/component";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import { setModalOpen } from "../../store/store";
import { useNavigate } from "react-router-dom";

export const Header: React.FC = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = useCallback(() => {
    window.location.href = window.location.hostname === 'test.tezapp.io' ? 'https://testapp.tezapp.io/' : 'https://app.tezapp.io/';
  }, []);

  const handleSignUp = useCallback(() => {
    dispatch(setModalOpen(true));
  }, []);

  const onNavigate = useCallback((path: string) => {
    navigate(path);
    setOpen(false);
  }, [navigate]);

  return (
    <header>
      <div className={clsx('menu', open && 'open')}>
        <div>
          <ul>
            <li onClick={() => onNavigate('/use-cases')}>Use cases</li>
            <li>Resources</li>
            <li>Contacts</li>
          </ul>
          <div className="socials">
            <img src="/images/twitter-dark.svg" alt="twitter" />
            <img src="/images/linkedin-dark.svg" alt="linkedin" />
            <img src="/images/google-dark.svg" alt="google" />
          </div>
        </div>
        <div className="buttons">
          <Button onClick={handleLogin} contained={false} color="primary">Log in</Button>
          <Button onClick={handleSignUp} contained color="primary">Get Started</Button>
        </div>
      </div>
      <div className="container header">
        <img onClick={() => onNavigate('/')} height="40" id="logo" src="/images/tezapp-logo.svg" alt="logo" />
        <ul className="d-md-flex d-none align-items-center">
          <li onClick={() => onNavigate('/use-cases')}>Use cases</li>
          <li>Resources</li>
          <li>Contacts</li>
        </ul>
        <div className="align-items-center d-md-flex d-none action-buttons">
          <ul>
            <li onClick={handleLogin}>Log in</li>
          </ul>
          <Button onClick={handleSignUp} className="ms-4">Get Started</Button>
        </div>
        <img
          onClick={() => setOpen(!open)}
          src={`/images/${open ? 'cross' : 'hamburger'}.svg`}
          alt="menu"
          className="d-block d-md-none"
        />
      </div>
    </header>
  )
};
