import React, {useEffect} from "react";
import {UseCaseItem} from "../../components/UseCaseItem/UseCaseItem";
import {TabSwitcher} from "../../components/TabSwitcher/TabSwitcher";
import {Button} from "../../components/Button/component";
import {CreateSteps} from "../../components/CreateSteps/CreateSteps";
import {Assay} from "../../components/Assay/Assay";

export const Localization: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <UseCaseItem
        title="Tezapp for Localization"
        subtitle="Tezapp is ideal as a Content hub source. Control, expansion, creation and editing will be done from one point. It can help increase traffic to your site, improve SEO, or center your organization's directories."
        imageSrc="/images/localization.png"
      />
      <TabSwitcher
        tabs={['Unlimited languages', 'Separate page for translators', 'Several projects']}
        body={[
          {
            title: 'Unlimited languages',
            description: 'You have access to create the languages or dialects you want. You can select and add any languages and translate content in those languages.',
            imageSrc: '/images/unlimited-languages.png',
            buttons: <Button className="stretch">Documentation</Button>
          },
          {
            title: 'Separate page for translators',
            description: 'The large amount of information stored in Tezapp should not prevent the translation team from doing their work. All content fields that support multilingualism are displayed on a separate page and are designed for maximum convenience for translators.',
            imageSrc: '/images/separate-page-for-translators.png',
            buttons: <Button className="stretch">Documentation</Button>
          },
          {
            title: 'Several projects - different localization',
            description: 'If your company has several applications and you use different languages for each of them, Tezapp supports this as well. \n' +
              'The distribution of content across projects provides not only convenience in differentiating the rights to information, but also provides the ability to bind the necessary languages to a specific application.',
            imageSrc: '/images/several-projects.png',
            buttons: <Button className="stretch">Documentation</Button>
          }
        ]}
      />
      <CreateSteps
        title="How do I create localized applications with Tezapp?"
        steps={[
          {
            label: 'Create languages',
            description: 'You can add any number of languages',
            imageSrc: '/images/create-languages.png'
          },
          {
            label: 'Create Project',
            description: 'It is necessary to create a project and link the languages created earlier to it.',
            imageSrc: '/images/create-project.png'
          },
          {
            label: 'Think over architecture',
            description: 'Create and think through data and content architectures. It all depends on your imagination!',
            imageSrc: '/images/think-over-architecture.png'
          },
          {
            label: 'Create your Models',
            description: 'Create the necessary models for your content with our constructor which supports many types of fields',
            imageSrc: '/images/create-your-models.png',
            breakAfter: true
          },
          {
            label: 'Fill Models with Content',
            description: 'Content is our everything. That\'s why its storage, importing and publishing are perfected.',
            imageSrc: '/images/fill-models-with-content.png'
          },
          {
            label: 'Build query in GraphQL',
            description: 'Secure access to your content, accelerate and extend the possibilities for the use of your data',
            imageSrc: '/images/build-query-in-graphql.png'
          },
          {
            label: 'Transfer Data',
            description: 'Returned in the request data, you can use consumer systems that can be developed on any technology stack',
            imageSrc: '/images/transfer-data.png'
          }
        ]}
      />
      <Assay />
    </div>
  );
};
