import React, {useEffect} from "react";
import {UseCaseItem} from "../../components/UseCaseItem/UseCaseItem";
import {TabSwitcher} from "../../components/TabSwitcher/TabSwitcher";
import {Button} from "../../components/Button/component";
import {CreateSteps} from "../../components/CreateSteps/CreateSteps";
import {Assay} from "../../components/Assay/Assay";

export const MobileApps: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <UseCaseItem
        title="Tezapp for Mobile Apps"
        subtitle="The speed and convenience of mobile app development will increase several times over, because Tezapp is a cross-platform, omni-channel solution for storing your app content.
If you want ease of use, flexibility in choosing your technology stack and fast scalability, Tezapp is what you need."
        imageSrc="/images/mobile-apps.png"
      />
      <TabSwitcher
        tabs={['Omni-channel solution', 'Cross-platform solution', 'GraphQL Flexibility', 'Technology stack']}
        body={[
          {
            title: 'Omni-channel solution',
            description: 'In today\'s digital world, Tezapp offers the right solution - providing content that is not limited to one device or one form of presentation. The user can log into your app from their phone, tablet, TV or any other available device, but the information and content they receive remains unchanged and managed from one place.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/omni-channel-solution.png'
          },
          {
            title: 'Cross-platform solution',
            description: 'Tezapp and its GraphQL Gateway makes no difference what platform your application is designed for - Android, iOS, Windows. Create a model, fill it with content, build a query and use it for any platform.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/fields-support.png'
          },
          {
            title: 'GraphQL Flexibility',
            description: 'Flexibility is what separates GraphQL technology from the well-known REST technology. In GraphQL, you control what you need to return from a query at a particular point in time. The content request is built based on exact needs, and doesn\'t clog the stream with unnecessary data.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/graphql-flexibility.png'
          },
          {
            title: 'Any platform, any technology stack',
            description: 'Store your content in Tezapp, but develop your mobile apps on any stack you\'re comfortable with. Tezapp supports integration with all known programming languages.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/technology-stack.png'
          }
        ]}
      />
      <CreateSteps
        title="How do I create my own application with Tezapp?"
        steps={[
          {
            label: 'Think over architecture',
            description: 'Create and think through data and content architectures. It all depends on your imagination!',
            imageSrc: '/images/think-over-architecture.png'
          },
          {
            label: 'Create your Models',
            description: 'Create the necessary models for your content with our constructor which supports many types of fields',
            imageSrc: '/images/create-your-models.png'
          },
          {
            label: 'Fill Models with Content ',
            description: 'Content is our everything. That\'s why its storage, importing and publishing are perfected.',
            imageSrc: '/images/fill-models-with-content.png',
            breakAfter: true
          },
          {
            label: 'Build query in GraphQL',
            description: 'Secure access to your content, accelerate and extend the possibilities for the use of your data',
            imageSrc: '/images/build-query-in-graphql.png'
          },
          {
            label: 'Transfer Data',
            description: 'Returned in the request data, you can use consumer systems that can be developed on any technology stack',
            imageSrc: '/images/transfer-data.png'
          }
        ]}
      />
      <Assay />
    </div>
  );
};
