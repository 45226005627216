type clientTextType = Record<string, {
  readonly title: string;
  readonly date: string;
  readonly description: string;
  readonly suggestions: ReadonlyArray<{ readonly title: string; readonly subtitle: string }>
}>;

export const textsOfClients: clientTextType = {
  kcell: {
    title: 'Company super application',
    date: 'December 2020',
    description: 'Kcell/Activ is a telecommunications giant from Kazakhstan, which set out to provide users with a multifunctional application and combine all products in it.<br>' +
      'Over 2M Android, IOS and Web app users see and consume content from Tezapp.',
    suggestions: [
      {
        title: '3',
        subtitle: 'Applications for 2 Brands'
      },
      {
        title: 'In 2 months',
        subtitle: 'MVP launch',
      },
      {
        title: '4x',
        subtitle: 'Faster content publishing'
      }
    ],
  },
  tele2: {
    title: 'Company mobile and web application',
    date: 'February 2021',
    description: 'Another telco with a million customer base has improved user experience with Tezapp. Centralized content management for applications of all platforms is a great use of Headless CMS.',
    suggestions: [
      {
        title: '2',
        subtitle: 'Applications for 2 Brands',
      },
      {
        title: 'In 1,5 months',
        subtitle: 'MVP launch',
      },
      {
        title: '2x',
        subtitle: 'Tech support expenses reducing'
      }
    ],
  },
  halyk: {
    title: 'In-App Loyalty Program',
    date: 'September 2021',
    description: 'The bank with the largest deposit portfolio in Kazakhstan trusted Tezapp to manage partner\'s data for their loyalty system.<br>' +
      'The content management platform helped launch the project in a short time and be in time for the New Year promotions.',
    suggestions: [
      {
        title: '5M+',
        subtitle: 'Bank app users',
      },
      {
        title: '10K+',
        subtitle: 'Bank partners/vendors work with tezapp Admin panel',
      },
      {
        title: 'In 1 month',
        subtitle: 'Loyalty program launch',
      }
    ]
  },
  telecom: {
    title: 'Company mobile and web application',
    date: 'May 2022',
    description: 'The project to transfer Kazakhtelecom applications to a modern technology stack and microservice architecture required the use of fresh ideas and solutions. Tezapp suited the needs of the customer as it is built on a microservice architecture using the latest technologies.',
    suggestions: [
      {
        title: '5x',
        subtitle: 'Faster content publishing',
      },
      {
        title: '1M+',
        subtitle: 'Users',
      },
      {
        title: '3x',
        subtitle: 'Faster translations',
      }
    ]
  },
  postkz: {
    title: 'Marketplace and Payments',
    date: 'December 2021',
    description: 'Tezapp was applied in the expansion of the post office business. As a content hub, Tezapp was used to manage the data of partners, vendors, products for the Marketplace application and the payment system.',
    suggestions: [
      {
        title: '5K+',
        subtitle: 'User per day',
      },
      {
        title: 'In 1 month',
        subtitle: 'MVP launch',
      },
      {
        title: '2x',
        subtitle: 'Faster content publishing',
      }
    ]
  }
};
