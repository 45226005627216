import React from "react";
import './styles.scss';

export const Footer: React.FC = () => {
  return (
    <footer className="container-">
      <div className="d-flex flex-column align-items-center">
        <img height="32" src="/images/footer-logo.svg" alt="footer logo" />
        <ul className="list-unstyled">
          <li>About us</li>
          <li>Product</li>
          <li>Resources</li>
          <li>Road map</li>
          <li>Contacts</li>
        </ul>
      </div>
      <div className="terms">
        <span className="rights">© 2023 Tezapp. All rights reserved.</span>
        <div className="socials">
          <img src="/images/twitter.svg" alt="twitter" />
          <img src="/images/linkedin.svg" alt="linkedin" />
          <img src="/images/google.svg" alt="google" />
        </div>
        <div className="links">
          <span>Terms</span>
          <span>Privacy</span>
          <span>Cookies</span>
        </div>
      </div>
    </footer>
  )
};
