import {Action, createSlice, configureStore} from "@reduxjs/toolkit";

type SliceState = {
    readonly modalOpen: boolean;
    readonly emailForModal: string;
}

const initialState: SliceState = {
    modalOpen: false,
    emailForModal: '',
};
const slice = createSlice({
    name: "mainSlice",
    initialState,
    reducers: {
        setModalOpen(
          state,
          action: Action & { readonly payload: boolean }
        ) {
            return {
                ...state,
                modalOpen: action.payload,
            };
        },
        setEmailForModal(
          state,
          action: Action & { readonly payload: string }
        ) {
            return {
                ...state,
                emailForModal: action.payload,
            };
        },
    }
});

export const { setModalOpen, setEmailForModal } = slice.actions;

export const { getModalOpen, getEmailForModal } = {
  getModalOpen(state: SliceState) {
      return state.modalOpen;
  },
  getEmailForModal(state: SliceState) {
      return state.emailForModal;
  }
};


const store = configureStore({
    reducer: slice.reducer,
});


export type AppDispatch = typeof store.dispatch;

export default store;
