import React, {useState} from "react";
import {Props} from "./props";
import clsx from "clsx";
import "./styles.scss";

export const TabSwitcher: React.FC<Props> = ({ tabs, body }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectedBody = body[selectedIndex];

  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <div
            onClick={() => setSelectedIndex(i)}
            key={tab}
            className={clsx('tab-item', selectedIndex === i && 'selected-item')}
          >
            {tab}
          </div>
        ))}
      </div>
      {selectedBody && (
        <div className="tab-content">
          <div>
            <h2>{selectedBody.title}</h2>
            {selectedBody.checkItems && (
              <div className="check-items">
                {selectedBody.checkItems.map((item, i) => (
                  <div key={i}>
                    <img src="/images/check-icon.svg" alt="" />
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            )}
            <p>{selectedBody.description}</p>
            <div className="buttons">
              {selectedBody.buttons}
            </div>
          </div>
          <img src={selectedBody.imageSrc} alt="" />
        </div>
      )}
    </div>
  )
};
