import React, {useEffect} from "react";
import {UseCaseItem} from "../../components/UseCaseItem/UseCaseItem";
import {TabSwitcher} from "../../components/TabSwitcher/TabSwitcher";
import {Button} from "../../components/Button/component";
import {CreateSteps} from "../../components/CreateSteps/CreateSteps";

export const Ecommerce: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <UseCaseItem
        title="Tezapp for Ecommerce and Marketplace"
        subtitle="Increase sales, speed up the release of products and goods on the market, increase profits - that's what every company wants, and that's what Tezapp will help you with. Tezapp takes care of personalization of services, product categories, page interface."
        imageSrc="/images/ecommerce-and-marketplace.png"
      />
      <TabSwitcher
        tabs={['Maintaining catalog', 'GraphQL Flexibility', 'Fast searching']}
        body={[
          {
            title: 'Maintaining catalog of products and services',
            description: 'Create the models you need, divide them among content managers, and content management and scalability won\'t bother you anymore.\n' +
              'All marketing campaigns can be personalized, promotions can be scheduled, and product launches can be planned in advance.',
            buttons: (
              <>
                <Button>Documentation</Button>
                <Button contained={false} color="dark">Learn more</Button>
              </>
            ),
            imageSrc: '/images/maintaining-catalog.png'
          },
          {
            title: 'GraphQL Flexibility',
            description: 'The speed of changes in GraphQL queries gives you a competitive advantage and allows you to respond quickly to market changes.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/graphql-flexibility.png'
          },
          {
            title: 'Fast searching',
            description: 'Comprehensive Filtering functionality in GraphQL queries can cover all needs of your application.\n' +
              'Built-in search engine will help your partners manage the catalog in admin panel.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/fast-searching.png'
          }
        ]}
      />
      <CreateSteps
        title="How do I create my own application with Tezapp?"
        steps={[
          {
            label: 'Create your Models',
            description: 'You can see examples of creating models for e-Com, Marketplace, products, services, promotions, etc. in our blog.',
            imageSrc: '/images/create-your-models_2.png',
            externalLink: 'e-Com blog'
          },
          {
            label: 'Fill Models with Content ',
            description: 'Content is our everything. That\'s why its storage, importing and publishing are perfected.',
            imageSrc: '/images/fill-models-with-content.png'
          },
          {
            label: 'Build query in GraphQL',
            description: 'Secure access to your content, accelerate and extend the possibilities for the use of your data',
            imageSrc: '/images/build-query-in-graphql.png'
          },
          {
            label: 'Transfer Data',
            description: 'Returned in the request data, you can use consumer systems that can be developed on any technology stack',
            imageSrc: '/images/transfer-data.png'
          }
        ]}
      />
    </div>
  );
};
