import React, {useEffect} from "react";
import {TabSwitcher} from "../../components/TabSwitcher/TabSwitcher";
import {Button} from "../../components/Button/component";
import {UseCaseItem} from "../../components/UseCaseItem/UseCaseItem";
import {Assay} from "../../components/Assay/Assay";
import {CreateSteps} from "../../components/CreateSteps/CreateSteps";

export const ContentHub: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <UseCaseItem
        title="Tezapp for Content Hub"
        subtitle="Tezapp is ideal as a Content hub source. Control, expansion, creation and editing will be done from one point. It can help increase traffic to your site, improve SEO, or center your organization's directories."
        imageSrc="/images/content-hub.png"
      />
      <TabSwitcher
        tabs={['Data import', 'Fields support', 'Versions and history', 'Data distribution']}
        body={[
          {
            title: 'Importing data from other sources',
            description: 'Tezapp is equipped with integration functionality that will allow you to set up communication with RESTapi from another system in a few clicks and download the necessary content.',
            buttons: (
              <>
                <Button>Documentation</Button>
                <Button contained={false} color="dark">Learn more</Button>
              </>
            ),
            imageSrc: '/images/data-import.png',
          },
          {
            title: 'All data types support',
            description: 'An extended list of supported field types will allow you to flexibly configure content storage. From simple types like Text and Integer to original ones like Geo-Point and ColorPicker. All of these types can be used when creating models.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/fields-support.png'
          },
          {
            title: 'Versions and history',
            description: 'All of these features give you complete control over the versions and history of your content.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/versions-n-history.png',
            checkItems: ['All changes remain with you', 'Any actions on content are stored in history', 'All publications generate new versions']
          },
          {
            title: 'All of these features give you complete control over the versions and history of your content.',
            description: 'GraphQL is responsible for transferring content.\n' +
              'Flexibility is what separates GraphQL technology from the well-known REST technology. In GraphQL, you have control over what data to share at any given moment.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/graphql-flexibility.png'
          }
        ]}
      />
      <CreateSteps
        title="How do I create my Content Hub with Tezapp?"
        steps={[
          {
            label: 'Think over architecture',
            description: 'Create and think through data and content architectures. It all depends on your imagination!',
            imageSrc: '/images/think-over-architecture.png'
          },
          {
            label: 'Create your Models',
            description: 'Create the necessary models for your content with our constructor which supports many types of fields',
            imageSrc: '/images/create-your-models.png'
          },
          {
            label: 'Fill Models with Content',
            description: 'Content is our everything. That\'s why its storage, importing and publishing are perfected.',
            imageSrc: '/images/fill-models-with-content.png',
            breakAfter: true
          },
          {
            label: 'Build query in GraphQL',
            description: 'Secure access to your content, accelerate and extend the possibilities for the use of your data',
            imageSrc: '/images/build-query-in-graphql.png'
          },
          {
            label: 'Transfer Data',
            description: 'Returned in the request data, you can use consumer systems that can be developed on any technology stack',
            imageSrc: '/images/transfer-data.png'
          }
        ]}
      />
      <Assay />
    </div>
  );
};
