import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Provider as ReduxProvider } from 'react-redux';
import store from "./store/store";
import {Home} from "./pages/Home/Home";
import {UseCases} from "./pages/UseCases/UseCases";
import {ContentHub} from "./pages/ContentHub/ContentHub";
import {Localization} from "./pages/Localization/Localization";
import {Ecommerce} from "./pages/Ecommerce/Ecommerce";
import {MobileApps} from "./pages/MobileApps/MobileApps";
import {WebApplications} from "./pages/WebApplications/WebApplications";

const rootElement = document.getElementById("root");

const APP = (
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="use-cases" element={<UseCases />} />
            <Route path="use-cases/content-hub" element={<ContentHub />} />
            <Route path="use-cases/localization" element={<Localization />} />
            <Route path="use-cases/ecommerce-and-marketplace" element={<Ecommerce />} />
            <Route path="use-cases/mobile-apps" element={<MobileApps />} />
            <Route path="use-cases/web-applications" element={<WebApplications />} />
          </Route>
          <Route path="/email/verify" element={<App />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="*" element={<h1>NOT FOUND</h1>} />
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
);

if (rootElement?.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
