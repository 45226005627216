import {Props, RequestType} from "./props";
import React, {ChangeEvent, FormEvent, useCallback, useEffect, useState} from "react";
import ReactModal from "react-modal";
import ReactLoading from 'react-loading';
import './styles.scss';
import clsx from "clsx";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const RegistrationModal: React.FC<Props> = ({ onClose, token, isOpen, email }) => {
  const [request, setRequest] = useState<RequestType>({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [password, setPassword] = useState<string>();
  const [repeatPassword, setRepeatPassword] = useState<string>();
  const [passwordError, setPasswordError] = useState(false);
  const mediaMatch = window.matchMedia('(min-width: 500px)').matches;

  const modalStyles = (media: boolean): ReactModal.Styles => ({
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 8,
      outline: 'none',
      width: '90%',
      maxWidth: '450px',
      maxHeight: '90%',
      padding: '40px 24px 24px',
      overflow: media ? 'unset' : 'auto',
      boxShadow: '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 1px 2px rgba(91, 104, 113, 0.32)',
      position: 'relative',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 9998,
    },
  });

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRequest((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  }, []);

  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, repeat: boolean) => {
    repeat ? setRepeatPassword(e.target.value) : setPassword(e.target.value);
  }, [])

  const onConfirm = useCallback(async () => {
    const filled = Boolean(request.email && request.firstName && request.secondName);
    if (filled) {
      fetch(
        'https://pelican-dev.alabs.space/console/customer',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Language': 'en'
          },
          body: JSON.stringify(request),
        }
      ).then((res) => {
        if (res.ok) {
          setStep(2);
        } else {
          res.json().then(json => {
            toast.error('Error: ' + json.message || res.status, { position: toast.POSITION.TOP_RIGHT });
          })
        }
      }).catch((e) => {
        toast.error('Error: ' + e?.response?.data?.message || e, { position: toast.POSITION.TOP_RIGHT });
      }).finally(() => {
        setHasError(false);
      });
    } else {
      setHasError(true);
    }
  }, [request]);

  const onConfirmPassword = useCallback(() => {
    const filled = Boolean(password && repeatPassword);
    const match = password === repeatPassword;
    if (filled && match) {
      const data = {
        token,
        password,
      }
      fetch(
        'https://pelican-dev.alabs.space/console/customer/verify-email',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'en'
          },
          body: JSON.stringify(data),
        },
      ).then((res) => {
        if (res.ok) {
          setStep(4);
        } else {
          res.json().then(json => {
            toast.error('Error: ' + json.message || res.status, { position: toast.POSITION.TOP_RIGHT });
          })
        }
      }).catch((e) => {
        toast.error('Error: ' + e?.response?.data?.message || e, { position: toast.POSITION.TOP_RIGHT });
      }).finally(() => {
        setPasswordError(false);
      });
    } else {
      setPasswordError(true);
    }
  }, [token, password, repeatPassword]);

  const onCheckToken = useCallback((tokenProp: string) => {
    setLoading(true);
    fetch(
      `https://pelican-dev.alabs.space/console/customer/verify-token?token=${tokenProp}`,
      {
        method: 'POST',
        headers: {
          'Accept-Language': 'en'
        }
      }
    ).then((res) => {
      if (res.ok) {
        setStep(3);
      } else {
        res.json().then(json => {
          toast.error('Error: ' + json.message || res.status, { position: toast.POSITION.TOP_RIGHT });
          onClose();
        })
      }
    }).catch((e: any) => {
      toast.error('Error: ' + e?.response?.data?.message || e, { position: toast.POSITION.TOP_RIGHT });
      onClose();
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    if (step === 1) {
      onConfirm();
    } else if (step === 3) {
      onConfirmPassword();
    }
    event.preventDefault();
  }, [step, onConfirm, onChangePassword, onConfirmPassword]);

  const proceedToLogin = (): void => {
    window.open(window.location.hostname === 'test.tezapp.io' ? 'https://testapp.tezapp.io' : 'https://app.tezapp.io', '_blank');
  }

  useEffect(() => {
    if (token) {
      onCheckToken(token);
    }
  }, [token]);

  useEffect(() => {
    email && setRequest({ email });
  }, [email]);

  return (
    <ReactModal
      preventScroll
      onRequestClose={onClose}
      isOpen={isOpen}
      style={modalStyles(mediaMatch)}
    >
      {loading && (
        <div className="loading">
          <ReactLoading type="spin" color="#0E4DEC" />
        </div>
      )}
      {!loading && (
        <form onSubmit={onSubmit} className="request-modal">
          <img onClick={onClose} className="close" src="/images/close-icon.svg" alt="close" />
          {(step == 0 || step == 1) && (
            <>
              <h4 className="text-center fw-bold title">Create an Account</h4>
              <p className="text-center secondary"><small>Start your 30-day free trial.</small></p>
            </>
          )}

          {step === 1 && (
            <>
              <div className="position-relative mb-2">
                <div className="divider" />
                <p className="fs-13 secondary text-center my-0">User info</p>
                <div className="divider end-0" />
              </div>

              <div className="form-group row mb-2 mt-1">
                <label htmlFor="email">Email/Login</label>
                <div>
                  <input
                    className={clsx(hasError && !request.email && 'error')}
                    value={request['email']}
                    onChange={onChangeInput}
                    type="email"
                    placeholder="@mail"
                    id="email"
                  />
                </div>
              </div>

              <div className="form-group row mb-2 mt-1">
                <label htmlFor="firstName">First name</label>
                <div>
                  <input
                    className={clsx(hasError && !request.firstName && 'error')}
                    onChange={onChangeInput}
                    type="text"
                    placeholder="First name"
                    id="firstName"
                  />
                </div>
              </div>

              <div className="form-group row mb-2 mt-1">
                <label htmlFor="secondNameName">Last name</label>
                <div>
                  <input
                    className={clsx(hasError && !request.secondName && 'error')}
                    onChange={onChangeInput}
                    type="text"
                    placeholder="Last name"
                    id="secondName"
                  />
                </div>
              </div>

              <button type="submit" className="primary-button">Confirm</button>
              <p className="already-account">
                Already have an account?
                <a href={window.location.hostname ? 'https://testapp.tezapp.io' : 'https://app.tezapp.io'}>Log in</a>
              </p>
            </>
          )}
          {step === 2 && (
            <>
              <h4 className="text-center fw-bold title">Check your email<br />for confirmation</h4>
              <p className="text-center secondary">
                <small>
                  We just send email to the address: <a href="#">{request.email}</a>.
                  Please check your inbox for a confirmation email
                </small>
              </p>
              <button onClick={onConfirm}>Resend email</button>
              <button onClick={onClose} className="primary-button mt-2">Ok</button>
              <p className="already-account">
                Already have an account?
                <a href={window.location.hostname ? 'https://testapp.tezapp.io' : 'https://app.tezapp.io'}>Log in</a>
              </p>
            </>
          )}
          {step === 3 && (
            <>
              <h4 className="text-center fw-bold title">Create Password</h4>
              <p className="text-center secondary">
                <small>Please fill in the form to complete the registration</small>
              </p>
              <div className="position-relative mb-2">
                <div className="divider" />
                <p className="fs-13 secondary text-center my-0">User info</p>
                <div className="divider end-0" />
              </div>

              <div className="form-group row mb-2 mt-1">
                <label htmlFor="password">Password</label>
                <div>
                  <input
                    className={clsx(passwordError && !password && 'error')}
                    onChange={(e) => onChangePassword(e, false)}
                    type="password"
                    placeholder="Password"
                    id="password"
                  />
                </div>
              </div>

              <div className="form-group row mb-3 mt-1">
                <label htmlFor="repeatPassword">Repeat Password</label>
                <div>
                  <input
                    className={clsx(passwordError && 'error')}
                    onChange={(e) => onChangePassword(e, true)}
                    type="password"
                    placeholder="Repeat Password"
                    id="repeatPassword"
                  />
                </div>
              </div>

              <button type="submit" className="primary-button">Confirm</button>
            </>
          )}
          {step === 4 && (
            <>
              <img src="/images/success.svg" className="d-flex mx-auto" alt="success" />
              <h4 className="text-center fw-bold title mt-3">Registration completed!</h4>
              <p className="text-center secondary mb-4">
                <small>Congratulations, your account has been created successfully!</small>
              </p>

              <button className="primary-button" onClick={proceedToLogin}>Proceed to Log In</button>
            </>
          )}
        </form>
      )}
    </ReactModal>
  );
};
