import React, {useEffect} from "react";
import {Button} from "../../components/Button/component";
import "./styles.scss";
import {Assay} from "../../components/Assay/Assay";
import {Link, useNavigate} from "react-router-dom";

export const UseCases: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="head-block">
        <div className="text-center d-flex flex-column align-items-center">
          <h1 className="title">Use Cases</h1>
        </div>
        <div className="use-cases d-flex gap-3 justify-content-center">
          <Button bordered onClick={() => navigate('/use-cases/content-hub')} contained={false} color="dark">Content Hub</Button>
          <Button bordered onClick={() => navigate('/use-cases/localization')} contained={false} color="dark">Localization</Button>
          <Button bordered onClick={() => navigate('/use-cases/ecommerce-and-marketplace')} contained={false} color="dark">Ecommerce and Marketplace</Button>
          <Button bordered onClick={() => navigate('/use-cases/mobile-apps')} contained={false} color="dark">Mobile Apps</Button>
          <Button bordered onClick={() => navigate('/use-cases/web-applications')} contained={false} color="dark">Web Applications</Button>
        </div>
      </div>
      <div className="about-block">
        <div className="about">
          <h2>Content Hub</h2>
          <p className="paragraph">Tezapp is ideal as a Content hub source. Control, expansion, creation and editing will be done from one point. It can help increase traffic to your site, improve SEO, or center your organization&#39;s directories.</p>
          <Link className="link" to="/use-cases/content-hub">Learn more <img src="/images/external-link-primary.svg" alt="" /></Link>
        </div>
        <img className="use-case-img" src="/images/content-hub.png" alt="Content-hub" />
      </div>
      <div className="about-block">
        <img className="use-case-img" src="/images/localization.png" alt="Localization" />
        <div className="about">
          <h2>Localization</h2>
          <p className="paragraph">Are your applications international and multi-country? No problem. Tezapp supports multilingual content and provides a user-friendly interface for localization management.</p>
          <Link className="link" to="/use-cases/localization">Learn more <img src="/images/external-link-primary.svg" alt="" /></Link>
        </div>
      </div>
      <div className="about-block">
        <div className="about">
          <h2>Ecommerce and Marketplace</h2>
          <p className="paragraph">Increase sales, speed up the release of products and goods on the market, increase profits - that&#39;s what every company wants, and that&#39;s what Tezapp will help you with. Tezapp takes care of personalization of services, product categories, page interface.</p>
          <Link className="link" to="/use-cases/ecommerce-and-marketplace">Learn more <img src="/images/external-link-primary.svg" alt="" /></Link>
        </div>
        <img className="use-case-img" src="/images/ecommerce-and-marketplace.png" alt="Ecommerce and Marketplace" />
      </div>
      <div className="about-block">
        <img className="use-case-img" src="/images/mobile-apps.png" alt="Mobile Apps" />
        <div className="about">
          <h2>Mobile Apps</h2>
          <p className="paragraph">The speed and convenience of mobile app development will increase several times over, because Tezapp is a cross-platform, omni-channel solution for storing your app content.
            If you want ease of use, flexibility in choosing your technology stack and fast scalability, Tezapp is what you need.</p>
          <Link className="link" to="/use-cases/mobile-apps">Learn more <img src="/images/external-link-primary.svg" alt="" /></Link>
        </div>
      </div>
      <div className="about-block">
        <div className="about">
          <h2>Web Applications</h2>
          <p className="paragraph">Tezapp&#39;s extensive functionality will simplify the creation of any site: content management, file storage, localization and providing data through GraphQL Tezapp takes care of.</p>
          <Link className="link" to="/use-cases/web-applications">Learn more <img src="/images/external-link-primary.svg" alt="" /></Link>
        </div>
        <img className="use-case-img" src="/images/web-applications.png" alt="Web Applications" />
      </div>
      <Assay />
    </div>
  );
};
