import React, {useCallback, useEffect, useState} from "react";
import {Button} from "../../components/Button/component";
import clsx from "clsx";
import {textsOfClients} from "../../const/helpers";
import {Assay} from "../../components/Assay/Assay";
import {useDispatch} from "react-redux";
import { setModalOpen } from "../../store/store";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export const Home: React.FC = () => {
  const [clientTab, setClientTab] = useState('halyk');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUp = useCallback(() => {
    dispatch(setModalOpen(true));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="main-info-block">
        <div>
          <h1 className="mb-4">
            Easiest way to manage your content
          </h1>
          <p className="text-secondary">Tezapp is a headless CMS platform for managing
            content for all of your digital channels from<br/>a single
            place, making it easier for both developers and
            business owners</p>
          <div className="buttons pt-4">
            <Button className="me-md-2 me-0" onClick={handleSignUp}>Get started</Button>
            <Button contained={false} color="dark">Learn more</Button>
          </div>
          <p className="mt-2 pt-1">✌️ Free 14-days trial, no credit card required</p>
        </div>
        <div className="preview">
          <video controls>
            <source src="/videos/tezapp_intro.mp4" type="video/mp4" />
            Your browser does not support video
          </video>
        </div>
      </div>

      <div className="benefits-block">
        <div className="benefits">
          <h2>Simplifies the work of all participants in the business process</h2>
          <div className="benefit">
            <div className="d-flex align-items-center">
              <img src="/images/tag.svg" alt="tag" />
              <h4>For Developers</h4>
            </div>
            <p className="subtitle">Get freedom in choosing a tech stack and tools, flexible integration, speed and convenience. Ready-made server-side architecture</p>
          </div>
          <div className="benefit">
            <div className="d-flex align-items-center">
              <img src="/images/stats.svg" alt="stats" />
              <h4>For Business owners</h4>
            </div>
            <p className="subtitle">Get the flexibility and speed of introducing new products and improvements, reducing costs</p>
          </div>
          <div className="benefit">
            <div className="d-flex align-items-center">
              <img src="/images/content.svg" alt="content" />
              <h4>For content managers</h4>
            </div>
            <p className="subtitle">Get ease of use, change information in time, no need for technical skills, and independence from developers</p>
          </div>
        </div>
        <div className="diagram-block">
          <img src="/images/diagram.svg" alt=""/>
          <p className="support-text">Tezapp is an universal and omnichannel system that allows you to create and manage content for different platforms from a single place, making it easier for both developers and business owners.</p>
        </div>
      </div>

      <div className="powered-block">
        <h2 className="text-center">Powered by Tezapp</h2>
        <div className="d-flex clients">
          <div
            onClick={() => setClientTab('halyk')}
            className={clsx('icon-container', clientTab === 'halyk' && 'active-icon')}
          >
            <img src={`/images/halyk${clientTab === 'halyk' ? '-active' : ''}.svg`} alt="halyk" />
          </div>
          <div
            onClick={() => setClientTab('telecom')}
            className={clsx('icon-container', clientTab === 'telecom' && 'active-icon')}
          >
            <img src={`/images/telecom${clientTab === 'telecom' ? '-active' : ''}.svg`} alt="activ" />
          </div>
          <div
            onClick={() => setClientTab('postkz')}
            className={clsx('icon-container', clientTab === 'postkz' && 'active-icon')}
          >
            <img src={`/images/postkz${clientTab === 'postkz' ? '-active' : ''}.svg`} alt="altel" />
          </div>
          <div
            onClick={() => setClientTab('kcell')}
            className={clsx('icon-container', clientTab === 'kcell' && 'active-icon')}
          >
            <img src={`/images/kcell${clientTab === 'kcell' ? '-active' : ''}.svg`} alt="kcell" />
          </div>
          <div
            onClick={() => setClientTab('tele2')}
            className={clsx('icon-container', clientTab === 'tele2' && 'active-icon')}
          >
            <img src={`/images/tele2${clientTab === 'tele2' ? '-active' : ''}.svg`} alt="tele2" />
          </div>
        </div>
        <div className="client-container">
          <div className="client-info">
            <span className="client-date">{textsOfClients[clientTab]['date']}</span>
            <h4 className="client-title">{textsOfClients[clientTab]['title']}</h4>
            <p className="client-description" dangerouslySetInnerHTML={{ __html: textsOfClients[clientTab]['description'] }} />
          </div>
          <img src={`/images/${clientTab}-app.png`} alt={`${clientTab}-app`} />
        </div>
        <div className="suggestions">
          {textsOfClients[clientTab]['suggestions'].map((s, i) => (
            <div key={i} className="suggestion">
              <h4 className="suggestion-title">{s.title}</h4>
              <span className="suggestion-description">{s.subtitle}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="examples-block">
        <div>
          <h2 className="text-center">Where I can use Tezapp</h2>
          <p className="subtitle text-center mx-auto">Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.</p>
        </div>
        <div className="examples">
          <div className="example">
            <img src="/images/example1.png" alt="example" />
            <div className="px-3">
              <h4 className="text-center mb-2">Marketplaces and E-commerce</h4>
              <p>With custom API integrations, Tezapp hCMS can help you scale your e-commerce business</p>
            </div>
          </div>
          <div className="example">
            <img src="/images/example2.png" alt="example" />
            <div className="px-3">
              <h4 className="text-center mb-2">Web & Mobile applications</h4>
              <p>No limits of traditional CMS platforms to deliver your content for mobile applications, web applications</p>
            </div>
          </div>
          <div className="example">
            <img src="/images/example3.png" alt="example" />
            <div className="px-3">
              <h4 className="text-center mb-2">Content Hub</h4>
              <p>Create systems from customer support portals to complex corporate intranet portals</p>
            </div>
          </div>
        </div>
        <Button onClick={() => navigate('/use-cases')} contained={false} color="dark" className="use-cases-link">
          <span>See all use cases</span>
          <img src="/images/external-link.svg" alt="link" />
        </Button>
      </div>

      <div className="steps-block">
        <h5 className="text-center">How it works</h5>
        <h2 className="text-center">Publish your content in a few steps</h2>
        <div className="steps">
          <div className="step text-center">
            <img className="step-image" src="/images/step1.png" alt="step" />
            <h4>Create Project</h4>
            <p className="support-text">Manage content for different projects from a single place</p>
            <img className="arrow-under" src="/images/under-arrow.svg" alt="arrow" />
            <img className="arrow-left" src="/images/arrow-left.svg" alt="arrow" />
          </div>
          <div className="step text-center">
            <img className="step-image" src="/images/step2.png" alt="step" />
            <h4>Set your Models</h4>
            <p className="support-text">The multifunctional designer allows you to create models of any scale: from simple to the most complex.</p>
            <img className="arrow-top" src="/images/top-arrow.svg" alt="arrow" />
            <img className="arrow-right" src="/images/arrow-right.svg" alt="arrow" />
          </div>
          <div className="step text-center">
            <img className="step-image" src="/images/step3.png" alt="step" />
            <h4>Add Content</h4>
            <p className="support-text">Built-in editors for text and digital assets, and add content in all languages of the world </p>
            <img className="arrow-under" src="/images/under-arrow.svg" alt="arrow" />
            <img className="arrow-left" src="/images/arrow-left.svg" alt="arrow" />
          </div>
          <div className="step text-center">
            <img className="step-image" src="/images/step4.png" alt="step" />
            <h4>Manage via GraphQL</h4>
            <p className="support-text">Secure access to your content, accelerate and extend the possibilities for the use of your data.</p>
          </div>
        </div>
      </div>

      <div className="tools-block">
        <div>
          <h2 className="text-center mx-auto">Manage content in your own way with Tezapp</h2>
          <p className="text-center mx-auto subtitle">Tezapp hCMS is an universal and omnichannel system that allows you to create and manage content for different platforms from a single place, making it easier for both developers and business owners.</p>
        </div>
        <div>
          <div className="tools-row">
            <div className="tools">
              <img src="/images/tool1.png" alt="tool" />
              <h4>GraphQl</h4>
              <p className="support-text">GraphQL content API allows you to create or modify your projects programmatically.</p>
            </div>
            <div className="tools">
              <img src="/images/tool2.png" alt="tool" />
              <h4>Visual Editor</h4>
              <p className="support-text">A multifunctional editor will make the work of a content manager more productive.</p>
            </div>
            <div className="tools">
              <img src="/images/tool3.png" alt="tool" />
              <h4>External integration</h4>
              <p className="support-text">The ability to connect external services and systems through the API technology.</p>
            </div>
          </div>
          <div className="tools-row">
            <div className="tools">
              <img src="/images/tool4.png" alt="tool" />
              <h4>Localization</h4>
              <p className="support-text">Allow Content Managers to localize and translate content into any language.</p>
            </div>
            <div className="tools">
              <img src="/images/tool5.png" alt="tool" />
              <h4>Asset Gallery</h4>
              <p className="support-text">The ability to store digital data of different types: pictures, video, audio and documents.</p>
            </div>
            <div className="tools">
              <img src="/images/tool6.png" alt="tool" />
              <h4>Content Versioning</h4>
              <p className="support-text">Logging of changes and versioning for easy tracking of changes in content</p>
            </div>
          </div>
          <div className="tools-row">
            <div className="tools">
              <img src="/images/tool7.png" alt="tool" />
              <h4>Environments</h4>
              <p className="support-text">For more convenient management of development stages and content updates</p>
            </div>
            <div className="tools">
              <img src="/images/tool8.png" alt="tool" />
              <h4>Modern tech stack</h4>
              <p className="support-text">Logging of changes and versioning for easy tracking of changes in content</p>
            </div>
            <div className="tools">
              <img src="/images/tool9.png" alt="tool" />
              <h4>Roles and Accesses</h4>
              <p className="support-text">Creating and managing custom roles and user access.</p>
            </div>
          </div>
        </div>
      </div>
      <Assay />
    </div>
  );
};
