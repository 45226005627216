import React, {useCallback, useEffect} from 'react';
import {Header} from "./components/Header/Header";
import './App.scss';
import {Footer} from "./components/Footer/Footer";
import {RegistrationModal} from "./components/RegistrationModal/component";
import {useLocation, useNavigate, Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setEmailForModal, setModalOpen, getEmailForModal, getModalOpen} from './store/store';

function App() {
  const search = useLocation().search;
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(search).get('token');
  const dispatch = useDispatch();

  const modalOpen = useSelector(getModalOpen);
  const email = useSelector(getEmailForModal);

  const onCloseModal = useCallback(() => {
    dispatch(setModalOpen(false));
    dispatch(setEmailForModal(''));
    if (location.pathname === '/email/verify') {
      navigate('/');
    }
  }, [navigate, location]);

  useEffect(() => {
    token && dispatch(setModalOpen(true));
  }, [token]);

  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
      {modalOpen && (
        <RegistrationModal
          token={token}
          onClose={onCloseModal}
          isOpen={modalOpen}
          email={email}
        />
      )}
      <ToastContainer style={{ zIndex: 9999 }} />
    </div>
  );
}

export default App;
