import React, {useEffect} from "react";
import {UseCaseItem} from "../../components/UseCaseItem/UseCaseItem";
import {TabSwitcher} from "../../components/TabSwitcher/TabSwitcher";
import {Button} from "../../components/Button/component";
import {CreateSteps} from "../../components/CreateSteps/CreateSteps";
import {Assay} from "../../components/Assay/Assay";

export const WebApplications: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <UseCaseItem
        title="Tezapp for Web Applications"
        subtitle="Tezapp's extensive functionality will simplify the creation of any site: content management, file storage, localization and providing data through GraphQL Tezapp takes care of."
        imageSrc="/images/web-applications.png"
      />
      <TabSwitcher
        tabs={['Content management', 'Content localization', 'File Storage', 'Query content via GraphQL']}
        body={[
          {
            title: 'Convenient and easy content management',
            description: 'Creating models and managing your site data is implemented in a user-friendly interface, with many features that allow you to quickly make changes and publish content literally in 2 clicks.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/content-management.png'
          },
          {
            title: 'Content localization',
            description: 'Does your site need to support multiple languages? No problem. PelicanCMS functionality allows you to create and apply to content any languages, and in any quantity.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/unlimited-languages.png'
          },
          {
            title: 'File Storage',
            description: 'Support for all file types is enabled: audio, video, documents, images, icons - any data on your site will be saved in the media gallery.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/file-storage.png'
          },
          {
            title: 'Query content via GraphQL',
            description: 'One entry point to retrieve all of your content.\n' +
              'Query only the data you need at a given moment.\n' +
              'Possibility to combine sources thanks to Apollo Federation.\n' +
              'All this and more - GrapgQL.',
            buttons: <Button className="stretch">Documentation</Button>,
            imageSrc: '/images/graphql-flexibility.png'
          }
        ]}
      />
      <CreateSteps
        title="How do I create my own application with Tezapp?"
        steps={[
          {
            label: 'Think over architecture',
            description: 'Create and think through data and content architectures. It all depends on your imagination!',
            imageSrc: '/images/think-over-architecture.png'
          },
          {
            label: 'Create your Models',
            description: 'Create the necessary models for your content with our constructor which supports many types of fields',
            imageSrc: '/images/create-your-models.png'
          },
          {
            label: 'Fill Models with Content ',
            description: 'Content is our everything. That\'s why its storage, importing and publishing are perfected.',
            imageSrc: '/images/fill-models-with-content.png',
            breakAfter: true
          },
          {
            label: 'Build query in GraphQL',
            description: 'Secure access to your content, accelerate and extend the possibilities for the use of your data',
            imageSrc: '/images/build-query-in-graphql.png'
          },
          {
            label: 'Transfer Data',
            description: 'Returned in the request data, you can use consumer systems that can be developed on any technology stack',
            imageSrc: '/images/transfer-data.png'
          }
        ]}
      />
      <Assay />
    </div>
  );
};
