import {Props} from "./props";
import React from "react";
import clsx from "clsx";
import './styles.scss';

export const Button: React.FC<Props> = ({ onClick, color = 'primary', contained = true, bordered = false, className, children }) => {
  return (
    <button onClick={onClick} className={clsx('button', className, color, `${color}-${contained ? 'contained' : 'text'}`, bordered && 'bordered')}>
      {children}
    </button>
  )
};
